import DashboardWrapper from './DashboardWrapper'
import { useMemo, useRef, useState } from 'react'
import Button from '../Button'
import { updateEvent, uploadEventFile } from '../../firestore'
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useSetAtom } from 'jotai'
import RundownCard from './partials/RundownCard'
import CreateNew from './partials/CreateNew'
import { IMAGE_TYPES, MAX_FILE_SIZE } from '../../constants'
import PropTypes from 'prop-types'
import { updateEventAtom } from '../../store/eventList.store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDownShortWide, faCircleNotch, faCircleXmark, faLink, faSearch } from '@fortawesome/free-solid-svg-icons'
import { ALPHABETICAL, CREATED_NEW_OLD, CREATED_OLD_NEW, RUNDOWN_START_TIME } from '../../constants/sortTypes.js'
import EventCollaborateModal from '../modal/EventCollaborateModal'
import { Menu, MenuItem } from '../interactives/DropdownMenu'
import Fuse from 'fuse.js'
import * as logger from '../../utils/logger'

export default function Event () {
  const {
    eventList,
    rundownList,
  } = useOutletContext()
  const { teamId, eventId } = useParams()
  const [updatingName, setUpdatingName] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const navigate = useNavigate()
  const setUpdateEvent = useSetAtom(updateEventAtom)
  const [searchText, setSearchText] = useState('')
  const [sortedBy, setSortedBy] = useState(RUNDOWN_START_TIME)

  const eventNameInputRef = useRef()

  const event = eventList.filter((event) => event.id === eventId)[0]
  const [eventName, setEventName] = useState(event?.name || '')
  const rundowns = rundownList.filter((rundown) => rundown.eventId === eventId && !rundown.archivedAt)

  const handleUpdateEvent = async (updateType, update) => {
    const { data } = await updateEvent(event.id, { [updateType]: update })
    setUpdateEvent(data)
    return data
  }

  const handleUpdateEventName = async () => {
    if (eventName === '' || eventName === event.name) return
    setUpdatingName(true)
    await handleUpdateEvent('name', eventName)
    setUpdatingName(false)
  }

  const handleOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleUpdateEventName()
      eventNameInputRef.current.blur()
    } else if (e.key === 'Escape') {
      setEventName(event.name)
      eventNameInputRef.current.blur()
    }
  }

  const sortItems = (items, sortBy) => {
    return items.slice().sort((a, b) => {
      switch (sortBy) {
        case RUNDOWN_START_TIME:
          return new Date(a.startTime) < new Date(b.startTime) ? 1 : -1
        case CREATED_NEW_OLD:
          return new Date(a.createdAt) < new Date(b.createdAt) ? 1 : -1
        case CREATED_OLD_NEW:
          return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1
        default: {
          const _A = a.name.toUpperCase()
          const _B = b.name.toUpperCase()
          return (_A < _B) ? -1 : (_A > _B) ? 1 : 0
        }
      }
    })
  }

  const sortedRundowns = useMemo(() => sortItems(rundowns.filter((r) => r.archivedAt == null), sortedBy), [rundowns, sortedBy])

  const filterItems = (items, searchText) => {
    const fuse = new Fuse(items, { keys: ['name'] })
    return searchText ? fuse.search(searchText).map((result) => result.item) : items
  }

  const filteredRundowns = useMemo(() => filterItems(sortedRundowns, searchText), [searchText, sortedRundowns])

  return (
    <DashboardWrapper
      title={(
        <div className="flex flex-col sm:flex-row gap-2 items-center sm:items-start">
          <Link to={`/dashboard/${teamId}`} className="text-gray-400 hover:text-gray-100 transition-colors">Dashboard</Link>
          <span className="text-gray-400 hidden sm:inline-block">→</span>
          <input
            ref={eventNameInputRef}
            value={eventName}
            className={[
              'relative w-full hover:underline focus:hover:no-underline! focus:outline-hidden focus:ring-3 rounded-sm text-center sm:text-left',
              updatingName ? 'bg-animated from-gray-800 to-gray-600' : 'bg-transparent',
            ].join(' ')}
            onChange={(e) => setEventName(e.target.value)}
            onClick={(e) => e.target.select()}
            onBlur={handleUpdateEventName}
            onKeyDown={handleOnKeyDown}
          />
          <EventLogo eventId={eventId} logo={event?.logo} handleUpdateEvent={handleUpdateEvent} />
          <Button
            text={(
              <p>
                Invite Guests
                {' '}
                <FontAwesomeIcon icon={faLink} />
              </p>
            )}
            className="text-sm mx-2"
            onClick={() => setShareModalOpen(true)}
            colour="dark"
            toolTip={'Share all rundowns on ' + event?.name}
          />
        </div>
      )}
    >

      <div className="mt-4 flex flex-col sm:flex-row gap-2 justify-between">
        <span className="flex-1"></span>
        <div className="pr-3 bg-gray-100/5 focus-within:bg-gray-100/10 focus-within:ring-3 rounded-sm w-full sm:w-min whitespace-nowrap flex items-center justify-between">
          <input
            placeholder="Find rundown"
            className="px-3 h-8 bg-transparent focus:outline-hidden"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
          />
          {searchText ? (
            <button className="h-7 focus:outline-hidden focus:ring-3 rounded-sm" onClick={() => setSearchText('')}>
              <FontAwesomeIcon icon={faCircleXmark} />
            </button>
          ) : <FontAwesomeIcon icon={faSearch} />}
        </div>

        <Menu label="Sort" icon={faArrowDownShortWide}>
          <MenuItem
            label="Rundown start time"
            onClick={() => setSortedBy(RUNDOWN_START_TIME)}
            active={sortedBy === RUNDOWN_START_TIME}
          />
          <MenuItem
            label="Created (latest)"
            onClick={() => setSortedBy(CREATED_NEW_OLD)}
            active={sortedBy === CREATED_NEW_OLD}
          />
          <MenuItem
            label="Created (oldest)"
            onClick={() => setSortedBy(CREATED_OLD_NEW)}
            active={sortedBy === CREATED_OLD_NEW}
          />
          <MenuItem
            label="Alphabetical"
            onClick={() => setSortedBy(ALPHABETICAL)}
            active={sortedBy === ALPHABETICAL}
          />
        </Menu>
      </div>

      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <CreateNew text="Create new rundown" handleOnCreate={() => navigate(`/create?event=${eventId}`)} />
        {filteredRundowns.map((rundown) => {
          return (
            <RundownCard key={rundown.id} rundown={rundown} />
          )
        })}
      </div>
      <EventCollaborateModal
        eventId={eventId}
        open={shareModalOpen}
        setOpen={setShareModalOpen}
      />
    </DashboardWrapper>
  )
}

const EventLogo = ({ eventId, logo, handleUpdateEvent }) => {
  const [loading, setLoading] = useState(false)

  const eventLogoInputRef = useRef()

  async function handleAddEventLogo (e) {
    const files = e.target.files

    if (files.length > 1) return window.alert('Cannot add more than one file at a time.')
    const file = files[0]

    if (!IMAGE_TYPES.includes(file.type)) return window.alert('File type not supported.')
    if (file.size > MAX_FILE_SIZE) return window.alert('Filesize must be 10MB or less.')

    try {
      setLoading(true)
      const { data } = await uploadEventFile(eventId, file)
      logger.log('[handleFileDrop]', data)
      handleUpdateEvent('logo', data.url)
    } catch (e) {
      logger.error('[file upload]', e)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="flex items-center gap-2 h-9 rounded-sm border border-white/20 px-3 text-sm">
        <span>Uploading</span>
        <FontAwesomeIcon icon={faCircleNotch} className="animate-spin" fixedWidth />
      </div>
    )
  }

  return (
    <div className="relative group">
      {logo && <img src={logo} className="h-12 object-contain max-w-[14rem]" />}
      <div className={[
        logo ? 'absolute opacity-0 group-hover:opacity-100 top-1 right-1 transition-opacity' : 'opacity-100',
      ].join(' ')}
      >
        <Button
          onClick={() => eventLogoInputRef.current.click()}
          text={logo ? 'Change' : 'Add logo'}
          className="text-sm"
          colour="dark"
          toolTip={logo ? '' : 'Appears on all rundowns within this event'}

        />
      </div>
      <input ref={eventLogoInputRef} type="file" className="hidden" accept={IMAGE_TYPES} onChange={handleAddEventLogo} />
    </div>
  )
}

EventLogo.propTypes = {
  eventId: PropTypes.string.isRequired,
  handleUpdateEvent: PropTypes.func.isRequired,
  logo: PropTypes.string,
}
