import { useCurrentEditor } from '@tiptap/react'
import { useEffect, useRef, FC } from 'react'

interface TipTapContentUpdaterProps {
  content?: string
}

/**
 * Component that updates the TipTap editor content when the `content` prop changes.
 * This is useful when the content needs to be updated from an external source.
 *
 * @param props - Component props.
 * @param props.content - The content to update the editor with.
 * @returns This component does not render anything.
 */
export const TipTapContentUpdater: FC<TipTapContentUpdaterProps> = ({ content = '' }) => {
  const { editor } = useCurrentEditor()
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (!editor) return
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const editorContent = editor.getHTML()
    if (editorContent !== content) {
      setTimeout(() => editor.commands.setContent(content))
    }
  }, [editor, content])

  return null
}
