import { useCurrentEditor } from '@tiptap/react'
import { useEffect, useRef, FC } from 'react'

type TipTapPropsUpdaterProps = {
  editable?: boolean
}

/**
 * Update TipTap editor props after the editor was instantiated.
 */
export const TipTapPropsUpdater: FC<TipTapPropsUpdaterProps> = ({ editable = true }) => {
  const { editor } = useCurrentEditor()
  const isFirstRender = useRef(true)

  useEffect(() => {
    if (!editor) return

    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    if (editor.isEditable !== editable) {
      editor.setEditable(editable)
    }
  }, [editor, editable])

  return null
}
