import { useCurrentEditor } from '@tiptap/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeading,
  faBold,
  faItalic,
  faSlash,
  faFont,
  faList,
  faListUl,
  faListOl,
  faListCheck,
  faTextSlash,
} from '@fortawesome/free-solid-svg-icons'
import { CELL_HIGHLIGHT_COLORS, CELL_TEXT_COLORS } from '@rundown-studio/consts'
import { useState, useEffect, MouseEventHandler, FC } from 'react'
import { useFloating, offset, autoUpdate, FloatingPortal } from '@floating-ui/react'
import * as logger from '../../utils/logger'
import mitt from 'mitt'
import { MenuBarButton } from './menu-bar-button'
import { useFloatingSetPositionReference } from './hooks/use-floating-set-position-reference.ts'
import { cn } from '@/utils'
import { ZIndex } from '../../constants/z-index.ts'
import { TipTapEvents } from './types.ts'

export const TipTapMenuBar: FC = () => {
  const { editor } = useCurrentEditor()
  const [emitter] = useState(mitt<TipTapEvents>())
  const { refs, floatingStyles } = useFloating({
    strategy: 'fixed',
    transform: false,
    placement: 'top-start',
    middleware: [
      offset({
        mainAxis: 5,
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  useFloatingSetPositionReference(refs.setPositionReference)

  // This is a early warning signal for performance issues
  logger.log('[TipTapMenuBar] rendered')

  // Close all other dropdowns except for the button menu option clicked
  function btnClick(name: string) {
    emitter.emit('close', { except: name })
  }

  // Prevent the default mousedown behavior which causes blur
  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault()
  }

  // Close all dropdown on editor blur
  useEffect(() => {
    const onBlur = () => {
      emitter.emit('close')
      refs.setPositionReference(null)
    }

    editor?.on('blur', onBlur)
    return () => {
      editor?.off('blur', onBlur)
    }
  }, [editor])

  if (!editor) return null

  if (!editor.isFocused) return null

  return (
    <FloatingPortal>
      <div
        ref={refs.setFloating}
        style={{
          ...floatingStyles,
          zIndex: ZIndex.TIP_TAP_MENU_BAR,
        }}
        className={cn(
          'flex items-center h-8 px-0.5 gap-0.5 z-50',
          'bg-gray-800 rounded-sm transition-opacity whitespace-nowrap ring-2 ring-black/50 shadow-lg',
        )}
        onMouseDown={handleMouseDown}
      >
        {/* Heading / Paragraph */}
        <MenuBarButton
          title="Paragraph"
          name="paragraph"
          options={[
            {
              key: 'heading-1',
              content: <span>H1</span>,
              isActive: editor.isActive('heading', { level: 1 }),
              onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
            },
            {
              key: 'heading-2',
              content: <span>H2</span>,
              isActive: editor.isActive('heading', { level: 2 }),
              onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
            },
            {
              key: 'heading-3',
              content: <span>H3</span>,
              isActive: editor.isActive('heading', { level: 3 }),
              onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
            },
            {
              key: 'paragraph',
              content: <span>P</span>,
              isActive: editor.isActive('paragraph'),
              onClick: () => editor.chain().focus().setParagraph().run(),
            },
          ]}
          emitter={emitter}
          onClick={() => btnClick('paragraph')}
        >
          <FontAwesomeIcon icon={faHeading} />
        </MenuBarButton>
        {/* Bold */}
        <MenuBarButton
          title="Bold"
          name="bold"
          onClick={() => {
            editor.chain().focus().toggleBold().run()
            btnClick('bold')
          }}
          isActive={editor.isActive('bold')}
        >
          <FontAwesomeIcon icon={faBold} />
        </MenuBarButton>
        {/* Italic */}
        <MenuBarButton
          title="Italic"
          name="italic"
          onClick={() => {
            editor.chain().focus().toggleItalic().run()
            btnClick('italic')
          }}
          isActive={editor.isActive('italic')}
        >
          <FontAwesomeIcon icon={faItalic} />
        </MenuBarButton>
        {/* List */}
        <MenuBarButton
          title="List"
          name="list"
          options={[
            {
              key: 'list-ul',
              content: <FontAwesomeIcon icon={faListUl} />,
              isActive: editor.isActive('bulletList'),
              onClick: () => editor.chain().focus().toggleBulletList().run(),
            },
            {
              key: 'list-ol',
              content: <FontAwesomeIcon icon={faListOl} />,
              isActive: editor.isActive('orderedList'),
              onClick: () => editor.chain().focus().toggleOrderedList().run(),
            },
            {
              key: 'list-check',
              content: <FontAwesomeIcon icon={faListCheck} />,
              isActive: editor.isActive('taskList'),
              onClick: () => editor.chain().focus().toggleTaskList().run(),
            },
          ]}
          isActive={editor.isActive('bulletList') || editor.isActive('orderedList') || editor.isActive('taskList')}
          emitter={emitter}
          onClick={() => btnClick('list')}
        >
          <FontAwesomeIcon icon={faList} />
        </MenuBarButton>
        {/* Color */}
        <MenuBarButton
          title="Text Color"
          name="color"
          options={[
            {
              key: 'text-color-none',
              content: (
                <span
                  className="inline-block align-middle w-5 h-5 rounded-xs border border-gray-800"
                  style={{ backgroundColor: 'white' }}
                >
                </span>
              ),
              isActive: !editor.isActive('textStyle'),
              onClick: () => editor.chain().focus().unsetColor().run(),
            },
            ...CELL_TEXT_COLORS.map((color) => ({
              key: 'text-color-' + color,
              content: (
                <span
                  className="inline-block align-middle w-5 h-5 rounded-xs border border-gray-800"
                  style={{ backgroundColor: color }}
                >
                </span>
              ),
              isActive: editor.isActive('textStyle', { color }),
              onClick: () => editor.chain().focus().setColor(color).run(),
            })),
          ]}
          emitter={emitter}
          onClick={() => btnClick('color')}
        >
          <FontAwesomeIcon
            style={{ color: editor.getAttributes('textStyle')?.color || 'white' }}
            icon={faFont}
          />
        </MenuBarButton>
        {/* Highlight */}
        <MenuBarButton
          title="Highlight Text"
          name="highlight"
          options={[
            {
              key: 'highlight-none',
              content: (
                <span
                  className="inline-block align-middle w-5 h-5 rounded-xs bg-gray-900 text-white"
                >
                  <FontAwesomeIcon icon={faSlash} />
                </span>
              ),
              isActive: !editor.isActive('highlight'),
              onClick: () => editor.chain().focus().unsetHighlight().run(),
            },
            ...CELL_HIGHLIGHT_COLORS.map((color) => ({
              key: 'highlight-' + color,
              content: (
                <span
                  className="inline-block align-middle w-5 h-5 rounded-xs border border-gray-800"
                  style={{ backgroundColor: color }}
                >
                </span>
              ),
              isActive: editor.isActive('highlight', { color }),
              onClick: () => editor.chain().focus().setHighlight({ color }).run(),
            })),
          ]}
          emitter={emitter}
          onClick={() => btnClick('highlight')}
        >
          <span
            className="inline-block align-text-top w-4 h-4 rounded-xs border"
            style={{ backgroundColor: editor.getAttributes('highlight')?.color || 'transparent' }}
          >
          </span>
        </MenuBarButton>
        {/* Image (TODO) */}
        {/* <MenuBarButton
        title="Upload Image"
        name="image"
        onClick={() => { btnClick('image') }}
        isActive={editor.isActive('image')}
      >
        <FontAwesomeIcon icon={faImage} />
      </MenuBarButton> */}
        {/* Remove Formatting */}
        <MenuBarButton
          title="Remove Formatting"
          name="clean-up"
          onClick={() => {
            editor.chain().focus().clearNodes().unsetAllMarks().run()
            btnClick('clean-up')
          }}
        >
          <FontAwesomeIcon icon={faTextSlash} />
        </MenuBarButton>
      </div>
    </FloatingPortal>
  )
}
