import PropTypes from 'prop-types'
import { Tooltip, TooltipTrigger, TooltipContent } from '../interactives/Tooltip.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePen } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect, useCallback } from 'react'
import _debounce from 'lodash/debounce'
import * as logger from '../../utils/logger'
import { useSavedState } from '../reusables/SavedStateIndicator.jsx'
import { TipTap } from '../tiptap'

/**
 * A component for editing cell items with an embedded rich text editor.
 * It manages the editing state, content synchronization, and debounced saving.
 */
export default function CellItemEditor ({
  rundownId,
  content = '',
  onUpdateContent = () => Promise.resolve(),
  readonly = false,
  eventEmitter,
  textSize = 'sm',
}) {
  const [coldContent, setColdContent] = useState(content)
  const [hotContent, setHotContent] = useState(content)
  const [focus, setFocus] = useState(false)
  const [locked, setLocked] = useState(false)
  const [highlighted, setHighlighted] = useState(false)
  const [didMount, setDidMount] = useState(false)

  // Set up saved state indicator
  const {
    indicator: savingIndicator,
    savingStart,
    savingDone,
    savingFail,
  } = useSavedState({ className: 'absolute z-30 bottom-1 right-1' })

  // Handles initial component load
  useEffect(() => {
    setColdContent(content)
    setHotContent(content)
    setDidMount(true)

    // Avoid triggering persist after closing and invalidating the rundown token
    return () => debouncedPersistContent.flush()
  }, [])

  // Applies content updates to unlocked cells
  useEffect(() => {
    if (!didMount || locked || coldContent === content) return
    setColdContent(content)
    setHotContent(content)
    highlight()
  }, [content])

  // Manages focus state and content persistence
  function onFocusUpdate (val) {
    setFocus(val)
    if (val) lockWithSelfUnblock(hotContent)
    else {
      debouncedPersistContent.cancel()
      onPersistContent(hotContent)
    }
  }

  // Updates hotContent and triggers locking logic
  function onTiptapUpdate (newHotContent) {
    if (newHotContent === hotContent) return
    logger.log('[CellItemEditor] onTiptapUpdate')
    setHotContent(newHotContent)
    lockWithSelfUnblock(newHotContent)
  }

  // Stable function to persist content changes, resets lock and saved state
  const onPersistContent = useCallback(async (hotContent) => {
    setLocked(false)
    if (hotContent === coldContent) return

    logger.log('[CellItemEditor] onPersistContent')
    setColdContent(hotContent)

    savingStart()
    try {
      await onUpdateContent(hotContent)
      savingDone()
    } catch {
      savingFail()
    }
  }, [coldContent, savingStart, savingDone, savingFail])

  // Debounced version of onPersistContent to delay execution
  const debouncedPersistContent = useCallback(
    _debounce(onPersistContent, 8000),
    [onPersistContent],
  )

  // Locks the editor and schedules content persistence
  function lockWithSelfUnblock (hotContent) {
    setLocked(true)
    debouncedPersistContent(hotContent)
  }

  // Temporarily highlights the cell to indicate an update
  function highlight () {
    setHighlighted(true)
    setTimeout(() => setHighlighted(false), 5000)
  }

  // Don't propagate drag-and-drop events from inside this component
  // Enables file drag-and-drop without interference with cue reorder
  function stopPropagation (event) {
    event.stopPropagation()
  }

  return (
    <div
      className={[
        'relative rounded-sm h-full transition',
        (!readonly && 'hover:bg-white/5'), // editable
        (focus && 'bg-white/5'),
      ].join(' ')}
      onDragStart={stopPropagation}
      onDrop={stopPropagation}
    >
      {/* Highlight outline */}
      {highlighted && (
        <div className="absolute inset-0 rounded-sm ring-3 ring-yellow-500 ring-inset animate-fade-out"></div>
      )}
      {/* Tiptap Editor */}
      <TipTap
        className={[
          'relative w-full h-full rounded-sm',
          { sm: 'text-sm', md: 'text-base' }[textSize],
        ].join(' ')}
        content={coldContent}
        onUpdateContent={onTiptapUpdate}
        onUpdateFocus={onFocusUpdate}
        rundownId={rundownId}
        readonly={readonly}
        eventEmitter={eventEmitter}
      />
      {/* Saved state indicator */}
      {savingIndicator}
      {/* Editor lock indicator */}
      {locked && (
        <Tooltip placement="left">
          <TooltipTrigger className="absolute z-30 bottom-1 right-1 text-blue-500 opacity-50 text-xs">
            <FontAwesomeIcon className="block" icon={faFilePen} size="sm" />
          </TooltipTrigger>
          <TooltipContent className="text-xs">
            Updates paused while you are editing this cell.
          </TooltipContent>
        </Tooltip>
      )}
      {/* Highlight dot */}
      {highlighted && (
        <Tooltip>
          <TooltipTrigger className="absolute -top-1.5 -left-1.5 h-3 w-3 text-yellow-500">
            <span className="absolute inset-0 rounded-full h-3 w-3 bg-current"></span>
            <span className="absolute inset-0 rounded-full h-3 w-3 bg-current opacity-75 animate-ping"></span>
          </TooltipTrigger>
          <TooltipContent className="text-xs">
            Content was recently updated.
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  )
}

CellItemEditor.propTypes = {
  rundownId: PropTypes.string.isRequired,
  content: PropTypes.string,
  onUpdateContent: PropTypes.func,
  readonly: PropTypes.bool,
  eventEmitter: PropTypes.object,
  textSize: PropTypes.oneOf(['sm', 'md']),
}
