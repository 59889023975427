import { EditorProvider, EditorEvents } from '@tiptap/react'
import { TipTapContentUpdater } from './tip-tap-content-updater'
import { TipTapPropsUpdater } from './tip-tap-props-updater'
import { TipTapFocusHandler } from './tip-tap-focus-handler'
import { TipTapMenuBar } from './tip-tap-menu-bar'
import StarterKit from '@tiptap/starter-kit'
import { Color } from '@tiptap/extension-color'
import ListItem from '@tiptap/extension-list-item'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons'
import { useRef, useState, KeyboardEvent, FC, HTMLAttributes } from 'react'
import { IMAGE_TYPES, MAX_FILE_SIZE, MAX_FREE_FILE_SIZE, BillingType } from '@/constants'
import { uploadRundownFile } from '../../firestore'
import FileNodeView from './extensions/FileNodeView'
import ImageNodeView from './extensions/ImageNodeView'
import { useAtomValue, useSetAtom } from 'jotai'
import { toastAtom } from '../../store/toast.store'
import UpgradeModal from '../modal/UpgradeModal'
import { planAtom } from '../../store/plan.store'
import formatBytes from '../../utils/formatBytes'
import { EditorProps, EditorView } from '@tiptap/pm/view'
import { Emitter } from 'mitt'
import { TipTapEvents } from './types.ts'

enum CursorPos {
  START = 'START',
  END = 'END',
  INSIDE = 'INSIDE',
  NONE = 'NONE',
}

interface TiptapProps extends HTMLAttributes<HTMLDivElement> {
  content?: string
  onUpdateContent: (html: string) => void
  rundownId: string
  readonly?: boolean
  onUpdateFocus?: (focused: boolean) => void
  eventEmitter?: Emitter<TipTapEvents>
}

export const TipTap: FC<TiptapProps> = ({
  content = '',
  onUpdateContent,
  rundownId,
  readonly = false,
  onUpdateFocus = () => {
  },
  eventEmitter,
  className = '',
  ...props
}) => {
  const [uploading, setUploading] = useState(false)
  const [upgradeModal, setUpgradeModal] = useState(false)
  const [cursorPos, setCursorPos] = useState<CursorPos>(CursorPos.NONE)
  const [isEmpty, setIsEmpty] = useState(true)
  const plan = useAtomValue(planAtom)
  const addToast = useSetAtom(toastAtom)
  const tipTapElement = useRef<HTMLDivElement>(null)

  const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    // TextStyle.configure({ types: [ListItem.name] }),
    TextStyle,
    StarterKit,
    Highlight.configure({
      multicolor: true,
      HTMLAttributes: {
        class: 'px-0.5 rounded-xs box-decoration-clone',
      },
    }),
    TaskItem,
    TaskList,
    Link,
    Placeholder.configure({
      placeholder: 'Type or drop a file...',
    }),
    FileNodeView,
    ImageNodeView,
  ]

  async function handleFileDrop(
    rundownId: string,
    view: EditorView,
    files: FileList,
  ): Promise<void> {
    const pos = view.posAtCoords({ left: (event as MouseEvent).clientX, top: (event as MouseEvent).clientY })?.pos
    if (pos == null) return

    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      if (file.size > MAX_FILE_SIZE) {
        addToast({ title: `File exceeds maximum size (${formatBytes(MAX_FILE_SIZE)}).`, type: 'fail' })
        return
      }
      if (!plan.features.includes(BillingType.BASIC) && file.size > MAX_FREE_FILE_SIZE) {
        setUpgradeModal(true)
        return
      }

      const { data } = await uploadRundownFile(rundownId, file)
      let newNode
      if (IMAGE_TYPES.includes(file.type)) {
        newNode = view.state.schema.nodes.imageComponent.create({ src: data.url })
      } else {
        newNode = view.state.schema.nodes.fileComponent.create({
          filename: file.name,
          size: formatBytes(file.size),
          type: file.type,
          url: data.url,
        })
      }
      const newTransaction = view.state.tr.insert(pos + index, newNode)
      view.dispatch(newTransaction)
    }
  }

  const editorProps: Partial<EditorProps> = {
    attributes: {
      class: 'px-3 py-2 h-full focus:outline-hidden focus:ring-3 rounded-sm',
    },
    handleDrop: (view, event, _, moved) => {
      if (!moved && event.dataTransfer?.files?.length) {
        setUploading(true)
        handleFileDrop(rundownId, view, event.dataTransfer.files).finally(() => {
          setUploading(false)
        })
      }

      return false
    },
  }

  function onKeyDown(event: KeyboardEvent<HTMLDivElement>) {
    if (!eventEmitter || !event.target || !(event.target as HTMLElement).isContentEditable || event.defaultPrevented) return

    const direction = {
      Tab: event.shiftKey ? 'left' : 'right',
      ArrowLeft: cursorPos === CursorPos.START ? 'left' : null,
      ArrowRight: cursorPos === CursorPos.END || isEmpty ? 'right' : null,
      ArrowUp: cursorPos === CursorPos.START ? 'up' : null,
      ArrowDown: cursorPos === CursorPos.END || isEmpty ? 'down' : null,
    }[event.key]

    if (direction) {
      eventEmitter.emit('jump', { direction, event })
    }
  }

  const onTransaction = ({
    editor,
    transaction,
  }: EditorEvents['transaction']) => {
    setIsEmpty(transaction.doc.content.size <= 2)
    if (!editor.isFocused) return setCursorPos(CursorPos.NONE)

    const pos = transaction.selection?.$head?.pos
    const size = transaction.doc.content.size

    if (pos <= 1) return setCursorPos(CursorPos.START)
    if (pos >= size - 1) return setCursorPos(CursorPos.END)
    return setCursorPos(CursorPos.INSIDE)
  }

  return (
    <div
      ref={tipTapElement}
      id="tiptap"
      className={[
        'isolate relative tiptap-wrapper text-[13px] transition-colors',
        className,
      ].join(' ')}
      {...props}
      onKeyDown={onKeyDown}
    >
      <EditorProvider
        extensions={extensions}
        content={content}
        editorProps={editorProps}
        editable={!readonly}
        onUpdate={({ editor }) => onUpdateContent(editor.getHTML())}
        onFocus={() => onUpdateFocus(true)}
        onBlur={() => onUpdateFocus(false)}
        onTransaction={onTransaction}
        onCreate={() => eventEmitter?.emit?.('onload')}
      >
        <TipTapContentUpdater content={content} />
        <TipTapPropsUpdater editable={!readonly} />
        <TipTapFocusHandler eventEmitter={eventEmitter} />
        {!readonly && <TipTapMenuBar />}
      </EditorProvider>
      {uploading && (
        <div className="absolute bottom-1 left-1 bg-black/40 rounded-sm px-1 text-blue-500 text-xs">
          <FontAwesomeIcon icon={faCloudArrowUp} size="sm" />
          <span className="ml-1">Uploading</span>
        </div>
      )}
      <UpgradeModal
        setOpen={setUpgradeModal}
        open={upgradeModal}
        message="This file exceeds the maximum of 5 MB available on the free plan."
      />
    </div>
  )
}
