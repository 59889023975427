import { useState, FC, PropsWithChildren, ReactNode } from 'react'
import { useFloating, offset, shift, FloatingPortal } from '@floating-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { Emitter } from 'mitt'
import { cn } from '@/utils'
import { TipTapEvents } from './types.ts'

type MenuBarButtonOption = {
  key: string
  content: ReactNode
  isActive: boolean
  onClick: () => void
}

type MenuBarButtonProps = {
  name: string
  title: string
  options?: MenuBarButtonOption[]
  onClick: () => void
  isActive?: boolean
  emitter?: Emitter<TipTapEvents>
}

export const MenuBarButton: FC<PropsWithChildren<MenuBarButtonProps>> = ({
  children,
  name,
  title,
  options = [],
  onClick,
  isActive,
  emitter,
}) => {
  const [optionsOpen, setOptionsOpen] = useState(false)
  const { refs, floatingStyles } = useFloating({
    placement: 'top-start',
    strategy: 'absolute',
    middleware: [offset({ mainAxis: 6, crossAxis: -2 }), shift()],
  })

  function btnOnClick() {
    if (options.length) setOptionsOpen(!optionsOpen)
    onClick()
  }

  function optOnClick(option: MenuBarButtonOption) {
    setOptionsOpen(false)
    option.onClick()
  }

  if (emitter) {
    emitter.on('close', (event) => {
      if (event?.except !== name) setOptionsOpen(false)
    })
  }

  return (
    <div className="relative">
      <button
        ref={refs.setReference}
        title={title}
        className={cn(
          'h-7 rounded-xs focus:outline-hidden focus:ring-3',
          (options.length ? 'px-1' : 'w-7'),
          (!isActive && 'bg-gray-800 hover:bg-gray-700 text-white'),
          (isActive && 'bg-white hover:bg-gray-200 text-gray-800'),
        )}
        onClick={btnOnClick}
      >
        {children}
        {options.length ? (
          <span className="ml-1 text-xs">
            <FontAwesomeIcon icon={faChevronDown} size="xs" />
          </span>
        ) : ''}
      </button>

      {optionsOpen
        ? (
            <FloatingPortal>
              <div
                ref={refs.setFloating}
                style={floatingStyles}
                className="z-30 w-[11.9rem] px-1 -ml-1"
              >
                <div
                  className={[
                    'inline-flex flex-wrap items-center p-0.5 gap-0.5',
                    'bg-gray-800 rounded-sm ring-2 ring-black/50 shadow-lg',
                  ].join(' ')}
                >
                  {options.map((option) => (
                    <button
                      key={option.key}
                      className={[
                        'h-7 w-7 rounded-xs focus:outline-hidden focus:ring-3',
                        (!option.isActive && 'bg-gray-800 hover:bg-gray-700'),
                        (option.isActive && 'bg-gray-100 text-gray-900 font-semibold'),
                      ].join(' ')}
                      onClick={optOnClick.bind(null, option)}
                    >
                      {option.content}
                    </button>
                  ))}
                </div>
              </div>
            </FloatingPortal>
          )
        : ''}
    </div>
  )
}
