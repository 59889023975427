import { useCurrentEditor } from '@tiptap/react'
import { useEffect, FC, useCallback } from 'react'
import { Emitter } from 'mitt'
import { TipTapEvents } from './types.ts'

type TipTapFocusHandlerProps = {
  eventEmitter?: Emitter<TipTapEvents>
}

/**
 * Handles the 'focus' event on the `eventEmitter`
 * Accepts `location` values from https://tiptap.dev/docs/editor/api/commands/focus
 */
export const TipTapFocusHandler: FC<TipTapFocusHandlerProps> = ({ eventEmitter }) => {
  const { editor } = useCurrentEditor()

  const handleFocus = useCallback((location: number) => {
    editor?.commands.focus(location)
  }, [])

  useEffect(() => {
    if (!eventEmitter || !editor) return

    eventEmitter.on('focus', handleFocus)
    return () => eventEmitter.off('focus', handleFocus)
  }, [eventEmitter, editor])

  return null
}
