import { useCurrentEditor } from '@tiptap/react'
import { useEffect } from 'react'
import { ExtendedRefs } from '@floating-ui/react'

export const useFloatingSetPositionReference = (setSelected: ExtendedRefs<HTMLElement>['setPositionReference']) => {
  const { editor } = useCurrentEditor()

  useEffect(() => {
    if (!editor) return

    const updatePosition = () => {
      const { view, state } = editor
      const { from } = state.selection

      const pos = state.doc.resolve(from)
      const domNode = view.nodeDOM(pos.before(pos.depth))

      if (domNode instanceof HTMLElement) {
        setSelected({
          getBoundingClientRect: () => domNode.getBoundingClientRect(),
          contextElement: domNode, // optional but useful for scroll-based positioning
        })
      }
    }

    editor.on('selectionUpdate', updatePosition)
    editor.on('focus', updatePosition)

    return () => {
      editor.removeAllListeners()
    }
  }, [editor])
}
