import { loadedCuesAtom, addLoadedCueAtom } from '../../store/rundown.store.js'
import { createContext, useEffect, useRef, useState } from 'react'
import { useAtomValue, useSetAtom } from 'jotai'
import * as logger from '../../utils/logger'
import PropTypes from 'prop-types'

export const CueLoadingContext = createContext({ loaded: false })

export function CueLoadingProvider ({
  children,
  cueId,
  className = undefined,
  style = {},
}) {
  const cueWrapperRef = useRef(null)
  const loadedCues = useAtomValue(loadedCuesAtom)
  const addLoadedCue = useSetAtom(addLoadedCueAtom)
  const intersectionObserverDidRun = useRef(false)
  const isInViewport = useRef(false)
  const [isLoaded, setIsLoaded] = useState(false)

  /**
   * Update this cue's `isLoaded` state based on `loadedCuesAtom`.
   */
  useEffect(() => {
    setIsLoaded(loadedCues.includes(cueId))
  }, [cueId, loadedCues])

  /**
   * Add this cue to `loadedCuesAtom` if in viewport.
   */
  function updateRenderState () {
    if (isInViewport.current && !isLoaded) {
      logger.log('[CueLoadingProvider] cue entered viewport', cueId)
      addLoadedCue(cueId)
    }
  }

  /**
   * Sets up an IntersectionObserver to update isInViewport when the component is in the viewport
   * and calls updateRenderState() after the callback is triggered for the first time after page load.
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        isInViewport.current = entry.isIntersecting
        if (!intersectionObserverDidRun.current) {
          intersectionObserverDidRun.current = true
          updateRenderState()
        }
      },
      { rootMargin: '10%' },
    )

    if (cueWrapperRef.current) {
      observer.observe(cueWrapperRef.current)
    }

    return () => {
      if (cueWrapperRef.current) {
        observer.unobserve(cueWrapperRef.current)
      }
    }
  }, [])

  /**
   * Sets up an event listener to detect scrolling and sets isScrolling state.
   */
  useEffect(() => {
    let isScrolling
    const onScroll = () => {
      clearTimeout(isScrolling)
      isScrolling = setTimeout(updateRenderState, 50)
    }
    const el = document.getElementById('rd-scroll-container')
    el.addEventListener('scroll', onScroll, { passive: true })
    return () => el.removeEventListener('scroll', onScroll)
  }, [])

  /**
   * Sets up an event listener to detect viewport resizing and calls updateRenderState.
   */
  useEffect(() => {
    window.addEventListener('resize', updateRenderState)
    return () => window.removeEventListener('resize', updateRenderState)
  }, [])

  return (
    <CueLoadingContext.Provider value={{ isLoaded }}>
      <div
        ref={cueWrapperRef}
        className={['relative', className].join(' ')}
        style={style}
      >
        {children}
      </div>
    </CueLoadingContext.Provider>
  )
}

CueLoadingProvider.propTypes = {
  children: PropTypes.any,
  cueId: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}
