import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import localStorage from '../../utils/local-storage'
import { useState, useEffect, useMemo } from 'react'
import { auxApi } from '../../axios.js'
import PropTypes from 'prop-types'
import snarkdown from 'snarkdown'

const STORAGE_KEY = 'rundownstudio:dismissedAlerts'
const DEFAULT_EMOJI = '📢'

const alertStyle = {
  PLAIN: 'Plain',
  IMPORTANT: 'Important',
  ALERT: 'Alert',
  WARNING: 'Warning',
}

const btnStyle = {
  TEXT: 'Text',
  BUTTON: 'Button',
}

export default function AnnouncementAlert ({ location, className }) {
  const [announcements, setAnnouncements] = useState([])
  const [dismissedIds, setDismissedIds] = useState(new Set(getLocalStorage(STORAGE_KEY, [])))

  const announcement = useMemo(
    () => announcements.filter((a) => !dismissedIds.has(a.id))[0] || null,
    [announcements, dismissedIds],
  )

  function dismissAnnouncement() {
    if (!announcement) return
    const newDismissedIds = new Set(dismissedIds)
    newDismissedIds.add(announcement.id)
    setDismissedIds(newDismissedIds)
    setLocalStorage(STORAGE_KEY, [...newDismissedIds])
  }

  async function fetchAnnouncement() {
    try {
      const { data } = await auxApi.get('announcements', { params: { location } })
      if (data.length) setAnnouncements(data)
    } catch (error) {
      console.error('[AnnouncementAlert] Failed to fetch announcement:', error)
    }
  }

  useEffect(() => {
    fetchAnnouncement()
  }, [])

  if (!announcement) return null

  const colorClasses = {
    [alertStyle.PLAIN]: 'bg-black/80 border-2 border-white/10 text-white',
    [alertStyle.IMPORTANT]: 'bg-linear-to-b from-blue-500 to-blue-700 text-white',
    [alertStyle.WARNING]: 'bg-linear-to-b from-amber-600 to-amber-700 text-white',
    [alertStyle.ALERT]: 'bg-linear-to-b from-red-600 to-red-800 text-white',
  }

  const buttonClasses = {
    [btnStyle.TEXT]: 'text-current hover:underline',
    [btnStyle.BUTTON]: 'px-3 py-1 rounded-md bg-neutral-900 text-white hover:bg-neutral-800',
  }

  return (
    <div
      className={[
        'rounded-lg shadow-lg flex items-center',
        className,
        colorClasses[announcement.Styling],
      ].join(' ')}
    >
      <div className="flex-none text-3xl pl-4 pr-1">
        {getFirstEmoji(announcement.Title)}
      </div>
      <div className="grow pl-3 py-3">
        <div className="font-semibold">
          {getTitleWithoutEmoji(announcement.Title)}
        </div>
        <div className="flex flex-wrap items-baseline gap-x-3 gap-y-1">
          <p
            className="text-sm sm:text-base prose prose-invert text-inherit max-w-full"
            dangerouslySetInnerHTML={{ __html: snarkdown(announcement.Text) }}
          />
          {announcement['Button Label'] && (
            <a
              href={announcement['Button URL']}
              target="_blank"
              rel="noreferrer"
              className={[
                'transition-colors',
                buttonClasses[announcement['Button Style']],
              ].join(' ')}
            >
              {announcement['Button Label']}
            </a>
          )}
        </div>
      </div>
      <div className="flex-none self-start min-w-4">
        {announcement['Dismissable'] && (
          <button
            className="rounded-lg h-10 w-10 text-current opacity-30 hover:opacity-80 transition-opacity"
            onClick={dismissAnnouncement}
          >
            <FontAwesomeIcon icon={faTimes} size="sm" />
          </button>
        )}
      </div>
    </div>
  )
}

AnnouncementAlert.propTypes = {
  functionUrl: PropTypes.string,
  location: PropTypes.string,
  className: PropTypes.string,
}

function getFirstEmoji (text = '') {
  const match = text.match(/\p{Emoji}/u)
  return match ? match[0] : DEFAULT_EMOJI
}

function getTitleWithoutEmoji (text = '') {
  return text.replace(/\p{Emoji}/u, '').trim()
}

function getLocalStorage (key, defaultValue) {
  const item = localStorage.getItem(key)
  return item ? JSON.parse(item) : defaultValue
}

function setLocalStorage (key, value) {
  localStorage.setItem(key, JSON.stringify(value))
}
