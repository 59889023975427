import localStorage from './local-storage'
import _clamp from 'lodash/clamp'

const LOG_LEVEL_KEY = 'rundown-studio:loglevel'
enum LOG_LEVELS {
  NONE,
  ERROR,
  INFO,
  ALL,
}

function getLogLevel() {
  const storedLevel = localStorage.getItem(LOG_LEVEL_KEY)
  const level = storedLevel ? parseInt(storedLevel) : LOG_LEVELS.NONE

  return _clamp(level, LOG_LEVELS.NONE, LOG_LEVELS.ALL)
}

export function setLogLevel(level: number) {
  localStorage.setItem(LOG_LEVEL_KEY, `${level}`)
}

function shouldLog(minimumLevel: number) {
  return getLogLevel() >= minimumLevel
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function log(...args: any) {
  if (shouldLog(LOG_LEVELS.ALL)) console.log(...args)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function info(...args: any) {
  if (shouldLog(LOG_LEVELS.INFO)) console.info(...args)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function warn(...args: any) {
  if (shouldLog(LOG_LEVELS.INFO)) console.warn(...args)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function error(...args: any) {
  if (shouldLog(LOG_LEVELS.ERROR)) console.error(...args)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debug(...args: any) {
  if (shouldLog(LOG_LEVELS.ALL)) console.debug(...args)
}

export const LogLevels = LOG_LEVELS
